<template>
  <div>
    <no-access-info-card v-if="showNoAccessInfoCard" />
    <div
      v-else
      class="d-flex flex-column-reverse flex-md-row"
    >
      <div class="col-md-8 nopadding">
        <div
          v-if="!companies || !companies.length"
          class="d-flex align-items-center justify-content-center"
        >
          <img
            src="@/assets/images/pages/course/empty-list.svg"
            alt="Buypass"
            class="d-inline-block mt-5"
          >
        </div>
        <div
          v-else
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <company-card
            v-for="company in companies"
            :key="company.slug"
            :company="company"
          />
          <b-pagination
            v-if="pagination && pagination.total > pagination.per_page"
            v-model="currentPage"
            :per-page="pagination.per_page"
            :total-rows="pagination.count"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
      <div class="col-md-4 pl-md-1 pr-md-0 p-0">
        <b-input-group class="input-group-merge mb-1">
          <b-input-group-prepend
            class="no-border"
            is-text
          >
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="search"
            type="search"
            class="no-border"
            :placeholder="$t('Search...')"
          />
        </b-input-group>
        <b-card body-class="p-1">
          <div v-if="currentCategory">
            <h4 class="font-weight-bolder">
              {{ $t("Subcategories") }}
            </h4>
            <b-form-checkbox
              v-for="subCategory in currentCategory.subCategories"
              :key="subCategory.slug"
              v-model="subcategories"
              :value="subCategory.slug"
              class="custom-control-primary mt-1"
            >
              <h5>{{ subCategory.name }}</h5>
            </b-form-checkbox>
          </div>
        </b-card>
      </div>
    </div>
  </div>

</template>
<script>
import {
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BCard,
  BFormCheckbox,
  BPagination,
} from 'bootstrap-vue'
import { debounce } from 'vue-debounce'
import CompanyCard from '@/components/pages/CompanyCard.vue'
import NoAccessInfoCard from '@/components/NoAccessInfoCard.vue'

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BCard,
    BFormCheckbox,
    BPagination,
    CompanyCard,
    NoAccessInfoCard,
  },
  data() {
    return {
      subcategories: [],
    }
  },
  watch: {
    // eslint-disable-next-line
    '$route.params.category': {
      async handler() {
        await this.fetchCompanies(this.subcategories.length)
        this.subcategories = []
      },
      deep: true,
    },
    subcategories: {
      handler() {
        this.fetchCompanies(this.subcategories.length)
      },
      deep: true,
    },
    search: {
      handler() {
        this.fetchCompanies(this.subcategories.length)
      },
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    showNoAccessInfoCard() {
      return this.user.user_info.approved_hrp === 1
    },
    categories() {
      return this.$store.getters['categories/categories']
    },
    currentCategory() {
      return this.categories.filter(el => el.slug === this.$route.params.category)[0]
    },
    userPermissions() {
      return this.$store.getters['auth/permissions']
    },
    companies() {
      return this.$store.getters['profileCompany/companies']
    },
    pagination() {
      return this.$store.getters['profileCompany/companiesPagination']
    },
    currentPage: {
      get() {
        return this.$store.getters['profileCompany/companiesPageNumber']
      },
      set(pageNumber) {
        this.$store.commit('profileCompany/setCompaniesPageNumber', pageNumber)
        this.fetchCompanies(this.subcategories.length)
      },
    },
    search: {
      get() {
        return this.$store.getters['profileCompany/companiesSearch']
      },
      // eslint-disable-next-line
      set: debounce(function(search) {
        this.$store.commit('profileCompany/setCompaniesSearch', search)
      }, 1000),
    },
  },
  created() {
    this.$store.dispatch('categories/fetchCategories').then(() => {
      if (this.showNoAccessInfoCard) return
      this.fetchCompanies(this.subcategories.length)
    })
  },
  methods: {
    async fetchCompanies(includeSubCategories) {
      if (this.showNoAccessInfoCard) return
      await this.$store.dispatch('profileCompany/fetchCompanies', includeSubCategories ? this.subcategories : [this.$route.params.category])
    },
  },
}
</script>

<template>
    <b-card @click="goToCompany" body-class="p-1" class="w-100 company-card mb-1">
        <div class="d-flex flex-column flex-md-row">
            <div class="col-md-4 pl-0 pr-0 pb-1 pb-md-0 pr-md-1">
                <img
                    :src="company.rectangle_image ? company.rectangle_image : require('@/assets/images/pages/profile-company/cover-placeholder.svg')"
                    class="w-100 company-card-image"
                    alt="Background image"
                >
            </div>
            <div class="col-md-6 p-0">
                <h5 v-if="company" class="font-weight-bold mb-0">{{ company.name[$i18n.locale] || company.name.no || company.name.en}}</h5>
                <h6 class="text-primary">{{ category.name }} | {{ category.subCategories[0].name }}</h6>
                <p v-html="company.about" class="mb-1 mb-md-1 ellipsis-3-line"></p>
            </div>
            <div class="col-md-2 align-items-end justify-content-end flex-column d-flex pr-0">
                <h6 class="font-weight-bold mb-0 text-muted">{{ company.created_at | transformDate }}</h6>
                <h6 class="mb-0 text-muted">{{ $t('Published') }}</h6>
            </div>
        </div>
    </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
  },
  props: {
    // eslint-disable-next-line
    company: {
      type: Object,
    },
  },
  filters: {
    transformDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },
  },
  computed: {
    category() {
      if (this.company.category.length) {
        return this.company.category[0]
      }
      return null
    },
  },
  methods: {
    goToCompany() {
      this.$router.push(`/profile_company/${this.company.slug}`)
    },
  },
}

</script>
